.textfieldContainer {
    margin-top: 10vh;
    padding-inline: 5%;
}

#gameid_input {
    width: 80vw;
}



@media only screen and (min-width: 418px) {
    .buttonContainer {
        flex-direction: row;
    }

    .buttonContainer .button {
        margin-inline: 2%;
        font-size: 1.5em;

    }
}