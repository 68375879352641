.custom_input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--tertiary);
}

.custom_input:focus {
  border: 1px solid black;
  outline-width: 0;
}

.custom_input::placeholder {
  font: 1rem/3 sans-serif;
}


@media screen and (-webkit-min-device-pixel-ratio:0) {

  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media only screen and (min-width: 418px) {
  .custom_input::placeholder {
    font: 1.5rem/3 sans-serif;
  }

  .custom_input {
    height: 5vh;
  }

}