#waitingUsersContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}

.waitingUser{
    height: 40px;
    width: 40px;
}

.waitingUser svg {
    fill : var(--tertiary);
}


@media only screen and (min-width: 418px) {
    .waitingUser{
        height: 80px;
        width: 80px;
    }
    
  
  }