
#gameTitle {
    color: var(--text);
    font-family: 'Jacques Francois';
    font-size: 13vw;
    margin-top: 25%;
}

@media only screen and (min-width: 418px) {
    
    #gameTitle {
        /* font-size: 8rem; */
        margin-top: 10%;
    }

}