  .dot {
    height: 25px;
    width: 25px;
    background-color: lightblue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 1%;
  }
  .player-cards-container {
    display: flex;
    /* justify-content: flex-end; */
    margin-right: 1%;
    position: relative;
    width: 100%;
    height: 70px;
    margin-left: 5px;
  }

  .playersCardItem {
    position: absolute;
    border: 1px solid black;
    background-color: lightgrey;
    border-radius: 25%;
    height: 20px;
    width: 11px;
  }

  .one-player-cards {
    position: relative;
    width: 42px;
  }
