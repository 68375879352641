.cardContainer {
  height: var(--card-height);
  width: 100%;
  border: 1px solid grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 50px;
  border-radius: 0.5rem;
  background-color: white;
  background-color: var(--card-background);
  padding: 5%;
  box-shadow: 2px 1px 2px grey;
}
.cardContainer.small {
  height: var(--card-height-small);
  min-width: 20px;
  border-radius: 0.5em;
}



.cardContainer img{
  height: 30px;
  border-radius: 50%;
}

.cardContainer.small img{
  display: none;
}

#cardStackContainer img{
  height: 15px;
}


.small .card-sm-num {
  font-size: 0.6rem;
}
.small .card-lg-num {
  font-size: 0.8rem;
}
.large .card-sm-num {
  font-size: 1rem;
}
.large .card-lg-num {
  font-size: 2rem;
}
