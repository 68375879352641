:root {
    --main: white;
    --secondary: #3f92c9;
    --tertiary: #BBE1FA;
    --text: black;
    --card-background: #d8d8d8;

}

.dark-mode{
    --main: #1B262C;
    --secondary: #0F4C75;
    --tertiary: #BBE1FA;
    --text: #DDE6ED;
    --card-background: #293c45;
}


#page {
    background-color: var(--main);
}

p {
    color: var(--text);
}
.text{
    color: var(--text);
}


.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5vh;
    width: 100%;
    align-items: center;
}

.buttonContainer .button {
    width: 90vw;
    background-color: var(--secondary);
    margin-bottom: 3%;
    color: var(--text);
    font-size: 1.1em;
}