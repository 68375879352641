.cardStackContainer {
    width: 95vw;
    height: calc(var(--card-height) + 10px);
    overflow-y: scroll;
}
.cardStackContainer.small {
    height: calc(var(--card-height-small) + 4px);
}

#mycards_cardContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    justify-content: start;
}

.buttonContainer.small{
    margin-bottom: 0%;
}